<template>
  <div>
    <div class="tab-bar">
      <div
          v-for="item in routeViews"
          :key="item.routerName"
          :class="{ active: item.routeName == $route.name }"
          @click="changeTabBar(item.routeName)"
      >
        {{ item.name }}
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "enterpriseService",
  data() {
    return {
      routeViews: [
        {name: "询价中", routeName: "enterpriseServiceInquiry"},
        {name: "已签约", routeName: "enterpriseServiceHasSigning"},
        {name: "未签约", routeName: "enterpriseServiceNoSigning"},
        {name: "已退款", routeName: "enterpriseServiceHasRefund"},
      ],
    };
  },
  methods: {
    changeTabBar(routerName) {
      this.$router.push({name: routerName});
    },
  },
};
</script>

<style scoped></style>
